import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        marginTop: theme.spacing.unit * 5,
        marginLeft: theme.spacing.unit * 4,
    },
});

class Contacts extends React.Component {

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Typography variant='title' color='textSecondary' paragraph>
                    If you have any questions, concerns or suggestions, feel free to write to <a href='mailto:support@defineeditor.com'>support@defineeditor.com</a>.
                    <br/>
                    You can also always get help in <a href='https://web.telegram.org/#/login'>Telegram</a> by joining the <a href='https://t.me/defineeditor'>Visual Define-XML Editor</a> group.
                </Typography>
            </div>
        );
    }
}

Contacts.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Contacts);
