import React from 'react';
import PropTypes from 'prop-types';
import MarkdownElement from '@material-ui/docs/MarkdownElement';
import { withStyles } from '@material-ui/core/styles';
import DownloadsTable from './downloadsTable.js';
/* eslint import/no-webpack-loader-syntax: off */
import downloadsPre from '!raw-loader!./downloadsPre.md';
import downloadsPost from '!raw-loader!./downloadsPost.md';

const styles = theme => ({
    root: {
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
    },
});


function Downloads(props) {
    const { classes } = props;

    return (
        <div className={classes.root}>
            <MarkdownElement text={downloadsPre} />
            <DownloadsTable currentVersion={props.currentVersion} currentStableVersion={props.currentStableVersion}/>
            <MarkdownElement text={downloadsPost} />
        </div>
    );
}

Downloads.propTypes = {
    classes: PropTypes.object.isRequired,
    currentVersion: PropTypes.string.isRequired,
};

export default withStyles(styles)(Downloads);
