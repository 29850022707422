import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Header from './header.js';
import Main from './main.js';
import Footer from './footer.js';

let currentVersion = '1.1.16-current';
let currentStableVersion = '1.0.0';

function Index (props) {
    return (
        <div>
            <CssBaseline />
            <Header currentVersion={currentVersion}/>
            <Main currentVersion={currentVersion} currentStableVersion={currentStableVersion}/>
            <Footer/>
        </div>
    );
}

export default Index;
