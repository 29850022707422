import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: '70%',
        margin: '0 auto',
    },
    tableRow: {
        fontSize: '12pt',
    },
    link: {
        color: '#0000EE',
        textDecoration: 'inherit',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
});

const tabNames = ['Windows','Mac','Linux'];
const versionNames = ['Current','Stable'];

function TabContainer(props) {
    return (
        <Typography component="div" style={{ padding: props.padding ? 8 * 3 : 0 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const CustomTableCell = withStyles(theme => ({
    body: {
        fontSize: 14,
    },
}))(TableCell);


class DownloadsTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentTab: 0,
            currentVersion: 0,
        };
    }

    handleChange = (attName) => (event, value) => {
        this.setState({
            [attName]: value,
        });
    }

    getDownloadsTable = (data, version) => {
        return (
            <Table>
                <TableBody>
                    {data.map( (row, index) => {
                        const { link, description } = row;
                        return (
                            <TableRow key={index} className={this.props.classes.tableRow}>
                                <CustomTableCell>{description}</CustomTableCell>
                                <CustomTableCell>
                                    <a
                                        href={'https://github.com/defineEditor/editor/releases/download/v' +
                                                encodeURIComponent(version) + '/' + encodeURIComponent(link)
                                        }
                                        target='_blank' rel='noopener noreferrer'
                                    >
                                        {link}
                                    </a>
                                </CustomTableCell>
                                <CustomTableCell>
                                    <Link
                                        to={'/releases/#version-' + version.replace('.','-')}
                                        className={this.props.classes.link}
                                    >
                                        Release Notes
                                    </Link>
                                </CustomTableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        );
    }

    getVersionTable = (downloads, versions) => {
        return (
            <React.Fragment>
                <AppBar position="relative" color='default'>
                    <Tabs
                        value={this.state.currentTab}
                        onChange={this.handleChange('currentTab')}
                        fullWidth
                        indicatorColor='primary'
                        textColor='primary'
                        scrollButtons="auto"
                    >
                        { tabNames.map( tab => {
                            return <Tab key={tab} label={tab} />;
                        })
                        }
                    </Tabs>
                </AppBar>
                <TabContainer padding>
                    {tabNames[this.state.currentTab] !== 'Mac' &&
                            this.getDownloadsTable(
                                downloads[versionNames[this.state.currentVersion].toLowerCase()][tabNames[this.state.currentTab]],
                                Object.values(versions)[this.state.currentVersion]
                            )
                    }
                    {tabNames[this.state.currentTab] === 'Mac' &&
                        <Typography>
                            Mac version is not available as requires MasOS to compile it. We don&#39;t have one, but we are looking into ways of compiling it.<br/>
                            If you are a Mac user, you can compile it yourself using instructions available on&nbsp;
                            <a href='https://github.com/defineEditor/editor' target='_blank' rel='noopener noreferrer'>GitHub</a>.<br/>
                            You can run the Windows version of the application on Mac using Wine.
                            <Typography variant='caption' gutterBottom>
                                In Wine settings, set Windows 7 as the OS version
                            </Typography>
                        </Typography>
                    }
                </TabContainer>
            </React.Fragment>
        );
    }

    render() {
        const { classes, currentVersion, currentStableVersion } = this.props;

        const versions = { current: currentVersion, stable: currentStableVersion };
        let downloads = {};
        Object.keys(versions).forEach(versionId => {
            let version = versions[versionId];
            downloads[versionId] = {
                Windows: [
                    {
                        description: 'Installation Package (64-bit)',
                        link: 'DefineEditor.Setup.' + version + '.exe'
                    },
                    {
                        description: 'Portable Version (64-bit)',
                        link: 'DefineEditor.' + version + '.exe'
                    },
                    {
                        description: 'Installation Package (32-bit)',
                        link: 'DefineEditor.Setup.' + version + '.ia32.exe'
                    },
                    {
                        description: 'Portable Version (32-bit)',
                        link: 'DefineEditor.' + version + '.ia32.exe'
                    },
                ],
                Mac: [
                    {
                        description: 'Zip',
                        link: 'DefineEditor.' + version + '.mac.zip',
                    },
                ],
                Linux: [
                    {
                        description: 'App Image',
                        link: 'DefineEditor.' + version + '.AppImage',
                    },
                    {
                        description: 'Deb package',
                        link: 'DefineEditor.' + version + '.deb',
                    },
                ],
            };
        });

        return (
            <div className={classes.root}>
                <AppBar position="relative">
                    <Tabs
                        value={this.state.currentVersion}
                        onChange={this.handleChange('currentVersion')}
                        fullWidth
                        scrollButtons="auto"
                    >
                        { versionNames.map( tab => {
                            return <Tab key={tab} label={tab} />;
                        })
                        }
                    </Tabs>
                </AppBar>
                <TabContainer>
                    {this.getVersionTable(downloads, versions)}
                </TabContainer>
            </div>
        );
    }
}

DownloadsTable.propTypes = {
    classes: PropTypes.object.isRequired,
    currentVersion: PropTypes.string.isRequired,
    currentStableVersion: PropTypes.string.isRequired,
};

export default withStyles(styles)(DownloadsTable);
