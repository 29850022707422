import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
        marginTop: theme.spacing.unit * 5,
    },
    poll: {
        margin: 'auto' ,
        display: 'block' ,
    },
});

class Poll extends React.Component {

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <iframe
                    src="https://docs.google.com/forms/d/e/1FAIpQLSeggA5vlMscr2lpQwVwLnMsNJ54rXwsc2NRgDhIbe2ol30D8Q/viewform?embedded=true"
                    title='Next Priorities'
                    width="640" height="4510" frameBorder="0"
                    className={classes.poll}>
                    Loading...
                </iframe>
            </div>
        );
    }
}

Poll.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Poll);
