import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import bsaVideo from '../static/video/bsa.mp4';
import reuseVideo from '../static/video/reuse.mp4';

const styles = theme => ({
    heroUnit: {
        backgroundColor: theme.palette.background.paper,
    },
    heroContent: {
        maxWidth: 600,
        margin: '0 auto',
        padding: `${theme.spacing.unit * 8}px 0 ${theme.spacing.unit * 6}px`,
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
            width: 1100,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    features: {
        padding: `${theme.spacing.unit * 8}px 0`,
    },
    highlights: {
        backgroundColor: theme.palette.background.paper,
    },
    card: {
        display: 'flex',
        verticalAlign: 'top',
    },
    cardContent: {
        flex: '1 1 auto',
    },
    video: {
        maxHeight: '70vh',
    },
    exampleText: {
        marginLeft: 'auto',
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        margin: 'auto',
        [theme.breakpoints.up('sm')]: {
            marginRight: -8,
        },
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
});

const features = [
    {
        title: 'Functional',
        description:
        'The editor integrates the most useful Excel functionality and features developed specifically for Define-XML development tasks.',
    },
    {
        title: 'User-Friendly',
        description:
        'No need to know complex Define-XML structures. Focus on editing dataset metadata, while the editor will take care of all the technical details.',
    },
    {
        title: 'Free',
        description:
        'Built using Electron, React, and Node.js, the editor is a free and open-source application. The source code is available under the GNU AGPL v3 license.',
    },
];

const highlights = [
    {
        title: 'Built-in Editors',
        description:
        'Build-in editors play a key role in the development process of the Define-XML metadata. Editors allow to edit logically grouped metadata in an interactive way. Each of the editors is developed specifically to address individual characteristics of a metadata attribute it is designed for.',
        media: bsaVideo,
        exampleText: 'Built-in Editor Example',
    },
    {
        title: 'Reuse existing metadata',
        description:
        'The editor allows to create a Define-XML document from scratch. Still in most situations studies share a lot of information, because they are based on the same CDISC standard. So it is much easier to copy existing metadata from other datasets or Define-XML documents and then update them with details specific to your study and dataset. Copy variables from another datasets in the same study or select a Define-XML document from another study and copy from it. Load CDISC Controlled Terminology into the editor and use it to quickly create your study codelists.',
        media: reuseVideo,
        exampleText: 'Metadata Reuse Example',
    },
];

class About extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            expanded: [ false, false ]
        };
    }

    handleExpandClick = (index) => () => {
        let newExpaded = this.state.expanded.slice();
        newExpaded[index] = !this.state.expanded[index];
        this.setState(state => ({ expanded: newExpaded }));
    };

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                {/* Hero unit */}
                <div className={classes.heroUnit}>
                    <div className={classes.heroContent}>
                        <Typography variant='display3' align='center' color='textPrimary' gutterBottom>
                            Visual Define-XML Editor
                        </Typography>
                        <Typography variant='title' align='center' color='textSecondary' paragraph>
                            A cross-platform desktop application which allows to edit and review Define-XML files in a convenient and efficient way.
                            It fully supports CDISC Define-XML v2.0 and ARM 1.0 standards.
                        </Typography>
                    </div>
                </div>
                {/* End hero unit */}
                <div className={classNames(classes.layout, classes.features)}>
                    <Grid container spacing={40}>
                        {features.map(feature => (
                            <Grid item key={feature.title} xs={12} md={4}>
                                <Card className={classes.card}>
                                    <div className={classes.cardContent}>
                                        <CardContent>
                                            <Typography variant='headline'>{feature.title}</Typography>
                                            <Typography variant='subheading' paragraph>
                                                {feature.description}
                                            </Typography>
                                        </CardContent>
                                    </div>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </div>
                <div className={classes.highlights}>
                    <Grid container spacing={40} className={classes.layout}>
                        {highlights.map( (highlight, index) => (
                            <Grid item key={highlight.title} xs={12}>
                                <Card className={classes.card}>
                                    <div className={classes.cardContent}>
                                        <CardContent>
                                            <Typography variant='headline'>{highlight.title}</Typography>
                                            <Typography variant='subheading' paragraph>
                                                {highlight.description}
                                            </Typography>
                                        </CardContent>
                                        <CardActions className={classes.actions} onClick={this.handleExpandClick(index)}>
                                            <Typography variant='subheading' className={classes.exampleText}>
                                                {highlight.exampleText}
                                            </Typography>
                                            <IconButton
                                                className={classNames(classes.expand, {
                                                    [classes.expandOpen]: this.state.expanded[index],
                                                })}
                                                aria-expanded={this.state.expanded[index]}
                                                aria-label="See example"
                                            >
                                                <ExpandMoreIcon />
                                            </IconButton>
                                        </CardActions>
                                        <Collapse in={this.state.expanded[index]} timeout="auto" unmountOnExit>
                                            <CardContent>
                                                <CardMedia
                                                    controls
                                                    className={classes.video}
                                                    image={highlight.media}
                                                    component='video'
                                                    title='Description editor'
                                                />
                                            </CardContent>
                                        </Collapse>
                                    </div>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </div>
                {/* End footer */}
            </React.Fragment>
        );
    }
}

About.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(About);
