import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es6/string';
import React from 'react';
import ReactDOM from 'react-dom';
import Index from './pages/index';
import { unregister } from './registerServiceWorker';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-127425744-1');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
    <BrowserRouter>
        <Index />
    </BrowserRouter>
    , document.querySelector('#root'));
unregister();
