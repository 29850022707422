import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const styles = theme => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
        [theme.breakpoints.up(1100 + theme.spacing.unit * 3 * 2)]: {
            width: 1100,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    footer: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 6,
    },
    link: {
        color: 'inherit',
        textDecoration: 'inherit',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
});

const footers = [
    {
        title: 'About',
        description: [
            {text: 'Main page', link: '/'},
            {text: 'Contacts', link: '/contacts'}
        ],
    },
    {
        title: 'Docs',
        description: [
            {text: 'Documentation', link: '/docs'},
            {text: 'Releases', link: '/releases'},
            {text: 'Trello (Development)', link: 'https://trello.com/b/h3bjgZNk'},
            {text: 'Trello (Releases)', link: 'https://trello.com/b/pD5uFwWA'},
        ],
    },
    {
        title: 'Resources',
        description: [
            {text: 'Downloads', link: '/downloads'},
            {text: 'GitHub', link: 'https://github.com/defineEditor/editor/'},
        ],
    },
    {
        title: 'Community',
        description: [
            {text: 'Telegram', link: 'https://t.me/defineeditor'},
            {text: 'WhatsApp', link: 'https://chat.whatsapp.com/HpBqZZboqCJ2fp7gOpxRZR'},
            {text: 'LinkedIn', link: 'https://www.linkedin.com/groups/12249170/'},
            {text: 'Twitter', link: 'https://twitter.com/defineeditor'},
            {text: 'Next Priorities', link: '/poll'},
        ],
    },
];

function Footer(props) {
    const { classes } = props;

    return (
        <footer className={classNames(classes.footer, classes.layout)}>
            <Grid container spacing={32} justify='space-evenly'>
                {footers.map(footer => (
                    <Grid item xs key={footer.title}>
                        <Typography variant='title' color='textPrimary' gutterBottom>
                            {footer.title}
                        </Typography>
                        {footer.description.map(item => (
                            <Typography key={item} variant='subheading' color='textSecondary'>
                                { item.link.startsWith('/') ? (
                                    <Link to={item.link} className={classes.link}>{item.text}</Link>
                                ) : (
                                    <a href={item.link} className={classes.link} target='_blank' rel='noopener noreferrer'>{item.text}</a>
                                )}
                            </Typography>
                        ))}
                    </Grid>
                ))}
            </Grid>
        </footer>
    );
}

Footer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);
