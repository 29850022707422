import React from 'react';
import { Switch, Route } from 'react-router-dom';
import About from './about.js';
import Downloads from './downloads/downloads.js';
import Docs from './docs/docs.js';
import Releases from './releases/releases.js';
import Contacts from './contacts.js';
import Poll from './poll.js';

function Main(props) {
    return (
        <main>
            <Switch>
                <Route exact path='/' component={About}/>
                <Route path='/downloads' render={() =>
                    <Downloads
                        currentVersion={props.currentVersion}
                        currentStableVersion={props.currentStableVersion}
                    />
                }
                />
                <Route path='/docs' component={Docs}/>
                <Route path='/releases' component={Releases}/>
                <Route path='/contacts' component={Contacts}/>
                <Route path='/poll' component={Poll}/>
            </Switch>
        </main>
    );
}

export default Main;
