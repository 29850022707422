import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    appBar: {
        position: 'relative',
    },
    toolbarTitle: {
        flex: 1,
    },
    version: {
        marginRight: theme.spacing.unit * 3,
    },
    link: {
        color: 'white',
        textDecoration: 'inherit',
    },
});


class Header extends React.Component {

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <AppBar position='static' className={classes.appBar}>
                    <Toolbar>
                        <Typography variant='h6' color='inherit' className={classes.toolbarTitle} noWrap>
                            Visual Define-XML Editor
                        </Typography>
                        <Typography variant='body2' color='inherit' className={classes.version} noWrap>
                            {this.props.currentVersion}
                        </Typography>
                        <Button onClick={() => { this.props.history.push('/'); }} className={classes.link}>
                            Home
                        </Button>
                        <Button onClick={() => { this.props.history.push('/releases'); }} className={classes.link}>
                            Releases
                        </Button>
                        <Button onClick={() => { this.props.history.push('/downloads'); }} className={classes.link}>
                            Downloads
                        </Button>
                    </Toolbar>
                </AppBar>
            </React.Fragment>
        );
    }
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Header));
