import React from 'react';
import PropTypes from 'prop-types';
import MarkdownElement from '@material-ui/docs/MarkdownElement';
import { withStyles } from '@material-ui/core/styles';
/* eslint import/no-webpack-loader-syntax: off */
import docs from '!raw-loader!./docs.md';

const styles = theme => ({
    root: {
        marginLeft: theme.spacing.unit * 3,
        marginRight: theme.spacing.unit * 3,
    },
});


function Docs(props) {
    const { classes } = props;

    return (
        <div className={classes.root}>
            <MarkdownElement text={docs} />
        </div>
    );
}

Docs.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Docs);
